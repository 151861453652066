import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/Layout"
import Status from "../../components/Status"
import WrapperView from "../../components/WrapperView"

const FaqPage = (props) => (
    <Layout {...props}>
    <Status auth={props.auth}  user={props.user}  />
    <WrapperView title="FAQ and How-To Guides">
    <div>
        <table className="flex flex-col">
            <tr key={`faq_1`} className="mb-4">
                <td className="w-24 center">
                    <div className="badge badge-primary">FAQ</div>
                </td>
                <td>
                    <Link to={`/faq/faq-self-signed-certificates`} className="link-underline">
                        Self-signed certificates: Understanding the Pros and Cons
                    </Link>
                </td>
            </tr>
            <tr key={`faq_2`} className="mb-4">
                <td className="w-24 center">
                    <div className="badge badge-primary">FAQ</div>
                </td>
                <td>
                    <Link to={`/faq/faq-ship-certificates-in-native-apps`} className="link-underline">
                        Can I ship certificates inside my native app?
                    </Link>
                </td>
            </tr>
            <tr key={`faq_3`} className="mb-4">
                <td className="w-24">
                    <div className="badge badge-secondary">How-To</div>
                </td>
                <td>
                    <Link to={`/faq/how-to-expressjs-ssl`} className="link-underline">
                        How to configure SSL certificate with Express.JS for an HTTPS server
                    </Link>
                </td>
            </tr>
        </table>
    </div>
    </WrapperView>
    </Layout>
)

export default FaqPage
